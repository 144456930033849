import PropTypes from "prop-types"
import React from "react"

import {bwr} from "./btn-wrap.module.css"

const BtnWrap = ({ children }) => (
    <div className={bwr}>
      {children}
    </div>
)

  BtnWrap.propTypes = {
    children: PropTypes.node.isRequired,
  }

  export default BtnWrap

import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Gpso3 from "/src/components/img/gpso/3"
import Gpso4 from "/src/components/img/gpso/4"
import Gpso5 from "/src/components/img/gpso/5"

export default () => (
  <Layout>
    <SEO title="Výpočet směrníku a rajónu v geodézii" />
    <h1>Výpočet směrníku a rajónu v geodézii</h1>

    <p>V&yacute;počet směrn&iacute;ku a rajonu je <strong>z&aacute;kladn&iacute; geodetick&aacute; &uacute;loha</strong>. V na&scaron;ich podm&iacute;nk&aacute;ch se nejčastěji poč&iacute;taj&iacute; v souřadnicov&eacute;m syst&eacute;mu S-JTSK, ve kter&eacute;m je osa +X orientov&aacute;na od severu na jih a osa +Y od v&yacute;chodu na z&aacute;pad.</p>
    <hr /><h2>Směrn&iacute;k</h2>
    <p><strong>Směrn&iacute;k</strong> slouž&iacute; pro v&yacute;počet polohy bodů a d&eacute;lky strany mezi dvěma body u nichž zn&aacute;me souřadnice. Jde o &uacute;hel měřen&yacute; v geodeticky kladn&eacute;m směru, kter&yacute; sv&iacute;r&aacute; osa x se stranou stanovisko &ndash; měřen&yacute; bod. Nab&yacute;v&aacute; hodnot 0 až 400 gonů a znač&iacute;me ho symbolem &sigma;.</p>
    <p>V&yacute;počet směrn&iacute;ku je n&aacute;sledovn&yacute;:</p>
    <ul>
    <li>Body A a B povedeme rovnoběžky se souřadn&yacute;mi osami X,Y a dostaneme pravo&uacute;hl&yacute; troj&uacute;heln&iacute;k o odvěsn&aacute;ch &Delta;X (XB - XA) a &Delta;Y (YB-YA).</li>
    <li>Vypočten&iacute;m přepony S(A,B) pomoc&iacute; Pythagorovy věty z&iacute;sk&aacute;me vzd&aacute;lenost mezi body A a B</li>
    <li>Tangenc pomocn&eacute;ho &uacute;hlu &phi; vypočteme dle vzorce <em>tan &phi; = &Delta;X / &Delta;Y</em>, pomocn&yacute; &uacute;hel dosahuje hodnoty maxim&aacute;lně 100 gonů</li>
    <li>Urč&iacute;me, v&nbsp;jak&eacute;m ze čtyř kvadrantů se dan&yacute; směrn&iacute;k nach&aacute;z&iacute;. A to buď nakreslen&iacute;m cel&eacute; situace (viz obr. 1), nebo dle znam&eacute;nek souřadnicov&yacute;ch rozd&iacute;lů (viz obr. 2). Na z&aacute;kladě t&eacute;to informace a hodnoty pomocn&eacute;ho &uacute;hlu &phi; z&iacute;sk&aacute;me v&yacute;slednou hodnotu směrn&iacute;ku &sigma;.</li>
    </ul>
    <Gpso3 />
    <Gpso4 />
    <hr /><h2>Raj&oacute;n</h2>
    <p>V&yacute;počet raj&oacute;nu je opačn&yacute;m postupem k&nbsp;v&yacute;počtu směrn&iacute;ku. Zn&aacute;me d&eacute;lku strany na nov&yacute; bod, směrn&iacute;k z&nbsp;orientace na dan&yacute; bod a poč&iacute;t&aacute;me nezn&aacute;me souřadnice bodu P.</p>
    <p>V&nbsp;modelov&eacute; situaci (viz obr. 3), kdy zn&aacute;me souřadnice bodu A a B a směrn&iacute;ky těchto bodů, změř&iacute;me &uacute;hel &omega; (&uacute;hel mezi |AB| a |AP|) a d&eacute;lku S&nbsp;A,P (vzd&aacute;lenost mezi body A a P).</p>
    <p>Nejprve z&iacute;sk&aacute;me směrn&iacute;k &sigma; A,P, kter&yacute; vypočteme jednoduch&yacute;m přičten&iacute;m &uacute;hlu &omega; ke směrn&iacute;ku &sigma; A,B.</p>
    <p>Pot&eacute; vypočteme souřadnicov&eacute; rozd&iacute;ly &Delta;X a &Delta;Y dle vzorců <em>&Delta;X = S&nbsp;A,P * cos (&sigma; A,P) </em>a <em>&Delta;Y = S&nbsp;A,P * sin (&sigma; A,P)</em>.</p>
    <p>Souřadnice bodu P dostaneme pak přičten&iacute;m souřadnicov&yacute;ch rozd&iacute;lů k souřadnic&iacute;m bodu A.</p>
    <Gpso5 />

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Výpočet směrníku a rajónu</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/priprava-pristroje-chyby-mereni-uhlu/"><button className="inv">&larr; Příprava přístroje a chyby při měření</button></Link>
    <Link to="/zaklady-geodezie-gps/gnss-systemy/"><button className="inv">GNSS systémy a jejich složení &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)

import PropTypes from "prop-types"
import React from "react"

import {ic} from "./img-con.module.css"

const ImgCon = ({ children }) => (
    <div className={ic}>
      {children}
    </div>
)

  ImgCon.propTypes = {
    children: PropTypes.node.isRequired,
  }

  export default ImgCon
